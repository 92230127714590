import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import styled from "styled-components"
import readingTime from "reading-time"
import SyntaxHighlighter from "react-syntax-highlighter"
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs"

import ContentWrapper from "../../styles/ContentWrapper"
import MainLayout from "src/components/MainLayout"

const FlexContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  margin: 0;
  h1.title {
    margin-bottom: 1rem;
  }

  .header {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.subtext};
    padding-left: 0.8rem;
    margin-bottom: 1.5rem;

    span.read-time {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
const StyledSection = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: row;
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  min-height: 60vh;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "center")};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }

  pre {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 5px;
    padding: 1.5rem;
    font-size: 0.8rem;
    overflow-x: scroll;

    & code {
      color: ${({ theme }) => theme.colors.subtext};
    }
  }

  code {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  ul > li > a {
    color: ${({ theme }) => theme.colors.subtext};
  }
`

const ExternalLink = props => {
  if (props.href.includes("ismaelmendonca.com") || props.href.includes("ismaelmt.com") || props.href[0] === "/") {
    return <Link to={props.href}>{props.children}</Link>
  }
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
}

const CodeHighlight = props => {
  return (
    <SyntaxHighlighter language="python" style={tomorrowNight} showLineNumbers={true}>
      {props.children.props.children}
    </SyntaxHighlighter>
  )
}

const shortcodes = { a: ExternalLink, pre: CodeHighlight } // Provide common components here

const ReadingTime = props => {
  return (
    <span>
      <span className="read-time"> {Math.round(readingTime(props.text).minutes)} min </span> read time
    </span>
  )
}

const ArticleHeader = ({ mdx }) => {
  console.log(mdx)
  return (
    <>
      <span>
        <b> Ismael Mendonça </b>
      </span>
      <ReadingTime text={mdx.body} />
      <span> {mdx.frontmatter.pubDate} </span>
    </>
  )
}

const Article = ({ data: { mdx }, pageContext }) => {
  console.log(mdx.frontmatter)
  return (
    <MainLayout splashScreen={false}>
      <StyledSection>
        <FlexContentWrapper>
          <h1 className="title">{mdx.frontmatter.title}</h1>
          <FlexContentWrapper className="header">
            <ArticleHeader mdx={mdx} />
          </FlexContentWrapper>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </FlexContentWrapper>
      </StyledSection>
    </MainLayout>
  )
}

export const query = graphql`
  query ArticleQuery($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        slug
        categories
        pubDate
      }
    }
  }
`

export default Article
